import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { searchClientePf } from "../../../services/banking";
import { ClientePf, ClientePfAprovado } from "../../../typings/ClientePf";
import { formatCpf } from "../../../utils/format";
import "./styles.scss"

const Users = () => {

    const navigate = useNavigate();
    const [users, setUsers] = useState<ClientePf[]>([]);
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(1);
    const [approvedFilter, setApprovedFilter] = useState('all');
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, approvedFilter]);

    const fetchUsers = async () => {
        console.log(`fetchUsers...`)
        setLoading(true);
        try {
            const res = await searchClientePf(page, approvedFilter, searchText);
            if (res.data) {
                setUsers(res.data)
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false);
    }

    const displayAprovadoStatus = (aprovado: ClientePfAprovado) => {
        const aprovadoStatus = {
            0: "Conferência manual",
            1: "Aguardando abertura",
            2: "Erro abertura",
            3: "Aprovada",
            4: "Recusada",
        }
        return aprovadoStatus[aprovado];
    }

    return (
        <div className="container-fluid">
            <div className="card my-4 p-4">
                <div className="row mb-4">
                    <h1 className="mb-4">Fila de Abertura</h1>
                    <div className="d-flex" style={{ gap: "20px" }}>
                        <div className="search-container align-items-center">
                            <select
                                value={approvedFilter} className="filter-select"
                                onChange={e => {
                                    const v = e.target.value;
                                    setApprovedFilter(v)
                                }}
                            >
                                <option value={'all'} >Todos</option>
                                <option value={'0'} >Conferência manual</option>
                                <option value={'1'} >Aguardando abertura</option>
                                <option value={'2'} >Erro abertura</option>
                                <option value={'3'} >Aprovada</option>
                                <option value={'4'} >Recusada</option>
                            </select>
                        </div>
                        <div className="search-container">
                            <input className="search-input" type="text"
                                value={searchText} onChange={e => setSearchText(e.target.value)} onKeyDown={e => e.key === 'Enter' && fetchUsers()} />
                        </div>
                    </div>
                </div>
                {loading ?
                    <div className="text-center mt-5">
                        Carregando...
                    </div>
                    :
                    (<div>
                        {(users && users.length > 0) ?
                            <div>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Nome</th>
                                            <th scope="col">CPF</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Criação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map(user =>
                                            <tr
                                                className="interactive-table-row"
                                                onClick={() => navigate("/user?id=" + user.id)}
                                                key={user.id}
                                            >
                                                <th scope="row">{user.id}</th>
                                                <td>{user.nome}</td>
                                                <td>{formatCpf(user.cpf)}</td>
                                                <td>{displayAprovadoStatus(user.aprovado)}</td>
                                                <td>{dayjs(user.createdAt).format("DD/MM/YYYY HH:mm")}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <div className="text-center mt-5">
                                Não foi encontrado nenhum usuário
                            </div>
                        }
                        <div className="text-center px-3 pt-3">
                            {page > 1 && <button className="page-selector" onClick={() => setPage(page - 1)}>{"<"}</button>}
                            <label className="page-selector">
                                {page}
                            </label>
                            {users.length > 19 && <button className="page-selector" onClick={() => setPage(page + 1)}>{">"}</button>}
                        </div>
                    </div>)
                }
            </div>
        </div >
    )
}

export default Users;
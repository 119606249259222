import api from "./api";
import { k4nRetryAccountCreationSteps } from "./dto/retry-account-creation.dto";

export function searchClientePf(page = 1, aprovado: string, pesquisa: string) {
  return api.get("/banking/cliente-pf/search", {
    params: {
      page,
      aprovado: aprovado === "all" ? null : aprovado,
      q: pesquisa || null,
    },
  });
}

export function searchSchedulesBoleto(page = 1, searchText: string) {
  return api.get("/banking/conta/schedules-boleto", {
    params: {
      page,
      q: searchText || null,
    },
  });
}

export function getBoleto(id: number) {
  return api.get("/banking/conta/scheduled-boleto", {
    params: {
      id,
    },
  });
}

export function searchSchedulesPix(page = 1, searchText: string) {
  return api.get("/banking/conta/schedules-pix", {
    params: {
      page,
      q: searchText || null,
    },
  });
}

export function getPix(id: number) {
  return api.get("/banking/conta/scheduled-pix", {
    params: {
      id,
    },
  });
}

export function searchSchedulesTransfer(page = 1, searchText: string) {
  return api.get("/banking/conta/schedules-transfer", {
    params: {
      page,
      q: searchText || null,
    },
  });
}

export function getTransfer(id: number) {
  return api.get("/banking/conta/scheduled-transfer", {
    params: {
      id,
    },
  });
}

export function searchSchedulesTed(page = 1, searchText: string) {
  return api.get("/banking/conta/schedules-ted", {
    params: {
      page,
      q: searchText || null,
    },
  });
}

export function getTed(id: number) {
  return api.get("/banking/conta/scheduled-ted", {
    params: {
      id,
    },
  });
}

export function searchProposals(page = 1, searchText: string) {
  return api.get("/insurance/proposals", {
    params: {
      page,
      q: searchText || null,
    },
  });
}

export function getProposal(id: number) {
  return api.get("/insurance/proposal", {
    params: {
      id,
    },
  });
}

export function getClientePfById(cliente_pf_id: number) {
  return api.get("/banking/cliente-pf/" + cliente_pf_id);
}

export function getClientePfByCpf(cpf: string) {
  cpf = cpf.replaceAll(".", "").replace("-", "");
  return api.get("/banking/cliente-pf/cpf/" + cpf);
}

export function approveOnBoarding(
  cpf: string,
  aprovado: boolean,
  observacao = ""
) {
  const data = {
    cpf: cpf.replaceAll(".", "").replace("-", ""),
    aprovado: aprovado,
    observacao: observacao || null,
  };
  return api.post("/banking/cliente-pf/aprovar", data);
}

export const processAberturaManual = (cpf: string) => {
  return api.get("/banking/cliente-pf/account-open-manual/" + cpf);
};

export const getConsoleUserByOidcId = (oidc_id: string) => {
  return api.get(`banking/console-user/oidc/` + oidc_id);
};

export const sendResetPassEmail = (cpf: string) => {
  return api.get("/banking/cliente-pf/reset-password/" + cpf);
};

export const retryAccountCreationSteps = (
  data: k4nRetryAccountCreationSteps
) => {
  return api.post("/banking/console-user/retry-steps", data);
};

export function formattedCurrency(currency: any) {
  return currency.toLocaleString(`pt-BR`, {
    style: `currency`,
    currency: `BRL`,
    minimumFractionDigits: 2,
  });
}

import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	approveOnBoarding,
	getClientePfById,
	processAberturaManual,
	retryAccountCreationSteps,
} from "../../../services/banking";
import { getClientePfDocuments } from "../../../services/ocr";
import { formatCpf } from "../../../utils/format";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import dayjs from "dayjs";
import "./styles.scss";
import { ClientePf, ClientePfAprovado } from "../../../typings/ClientePf";
import JSONInput from "react-json-editor-ajrm";
import * as locale from "react-json-editor-ajrm/locale/pt";

import NavbarEye from "../../../assets/images/navbar-banking/navbar_eye.svg";
import { searchLogTransactions } from "../../../services/log-index";
import {
	FaAngleDoubleDown,
	FaAngleDoubleRight,
	FaArrowDown,
	FaArrowRight,
	FaRetweet,
} from "react-icons/fa";
import {
	k4nRetryAccountCreationSteps,
	MissinStepsObject,
} from "../../../services/dto/retry-account-creation.dto";
import { ContaClietePf } from "../../../typings/ContaClintePf";
import { AxiosResponse } from "axios";

const UserPage = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [user, setUser] = useState<ClientePf>();
	const [account, setAccount] = useState<ContaClietePf>();
	const [userDocs, setUserDocs] = useState<any>([]);
	const [accountLogTransactions, setAccountLogTransactions] = useState<any>([]);
	const [displayLogDetails, setDisplayLogDetails] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [denyBox, setDenyBox] = useState(false);
	const [isStepMissin, setIsStepMissing] = useState<boolean>(false);
	const [loadingStepsTry, setLoadingStepsTry] = useState<boolean>(false);
	//missingSteps
	const [conta, setConta] = useState(false);
	const [cedente, setCedente] = useState(false);
	const [pix, setPix] = useState(false);
	const [wallet, setWallet] = useState(false);

	useEffect(() => {
		const q = new URLSearchParams(location.search);
		const user_id = Number(q.get("id"));
		if (user_id) {
			fetchUser(user_id);
		}
	}, [location.search]);

	useLayoutEffect(() => {
		updateMissingSteps();
	}, [accountLogTransactions, cedente, conta, pix, wallet]);

	useLayoutEffect(() => {
		if (!cedente || !conta || !pix || !wallet) {
			setIsStepMissing(true);
		} else {
			setIsStepMissing(false);
		}
	}, [accountLogTransactions, cedente, conta, pix, wallet]);

	const fetchUser = async (user_id: number) => {
		setLoading(true);
		try {
			const usr = await getClientePfById(user_id);
			if (usr.data && usr.data.id) {
				setAccount(usr.data.accounts[0]);
				setUser(usr.data);
				const resDocs = await getClientePfDocuments(user_id);
				setUserDocs(resDocs.data);
				console.log(usr);
				console.log(resDocs);

				//fetch log transactions if account exists
				if (
					usr.data.accounts &&
					usr.data.accounts.length > 0 &&
					usr.data.accounts[0].transactionKey
				) {
					const transactions = await searchLogTransactions(
						usr.data?.accounts[0].transactionKey
					);
					if (transactions.data) {
						const trans = transactions.data.sort((a: any, b: any) => {
							return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
						});
						setAccountLogTransactions(trans);
					}
				}
			}
		} catch (error) {
			console.error(error);
			window.alert(error);
		}
		setLoading(false);
	};

	const updateMissingSteps = () => {
		accountLogTransactions.forEach((txData: any) => {
			const uri: string[] = txData.req?.uri.split(".br/");

			if (uri[1] === "api/conta") {
				// newMissingSteps.conta = txData.res?.sucesso;
				setConta(txData.res?.sucesso);
			}
			if (uri[1] === "api/Cedente") {
				// newMissingSteps.cedente = txData.res?.sucesso;
				setCedente(txData.res?.sucesso);
			}
			if (uri[1] === "api/Chave/Criar") {
				// newMissingSteps.pix = txData.res?.sucesso;
				setPix(txData.res?.sucesso);
			}
			if (uri[1] === "api/Cedente/AdicionarCarteira") {
				// newMissingSteps.wallet = txData.res?.sucesso;
				setWallet(txData.res?.sucesso);
			}
		});
	};

	const denyProposal = async () => {
		const obs = (
			document.getElementById("observacao-input") as HTMLTextAreaElement
		)?.value;
		if (obs) {
			setLoading(true);
			try {
				if (user?.cpf) {
					await approveOnBoarding(user?.cpf, false, obs);
					navigate("/users");
				}
			} catch (error) {
				console.error(error);
				window.alert(error);
			}
			setLoading(false);
		} else {
			window.alert("Digite o motivo da recusa");
		}
	};

	const approveProposal = async () => {
		setLoading(true);
		try {
			if (user?.cpf) {
				await approveOnBoarding(user?.cpf, true);
				navigate("/users");
			}
		} catch (error) {
			console.error(error);
			window.alert(error);
		}
		setLoading(false);
	};

	const openAccount = async () => {
		setLoading(true);
		try {
			if (user?.cpf) {
				await processAberturaManual(user?.cpf);
				navigate("/users");
			}
		} catch (error) {
			console.error(error);
			window.alert(error);
		}
		setLoading(false);
	};

	const toggleDisplayDoc = (i: number) => {
		const newDocs = [...userDocs];
		newDocs[i].displayDetails = !!!userDocs[i].displayDetails;
		setUserDocs(newDocs);
	};

	const toggleDisplayLog = (i: number) => {
		if (!displayLogDetails[i]) displayLogDetails[i] = false;
		const newLog = [...displayLogDetails];
		newLog[i] = !!!displayLogDetails[i];
		setDisplayLogDetails(newLog);
	};

	const retryMissinSteps = async () => {
		setLoadingStepsTry(true);

		//Check the missing steps state
		updateMissingSteps();

		//Mount the missing steps array to send to api call
		const mountMissingStepsArray = (data: MissinStepsObject) => {
			const array = [];
			if (!data.wallet) {
				array.push("wallet");
			}
			if (!data.cedente) {
				array.push("cedente");
			}
			if (!data.pix) {
				array.push("pix");
			}
			return array;
		};

		let payload: k4nRetryAccountCreationSteps;

		// Mount the missing steps obj
		if (!conta) {
			payload = {
				retry_steps: [],
				clientePf: { id: user!.id },
				nome: `${user!.nome}`,
				tipoPessoa: account!.tipoPessoa,
			};
		} else {
			if (!cedente || !pix || !wallet) {
				payload = {
					retry_steps: mountMissingStepsArray({
						cedente,
						conta,
						pix,
						wallet,
					}),
					clientePf: { id: user!.id },
					nome: `${user!.nome}`,
					tipoPessoa: account!.tipoPessoa,
					conta: {
						agencia: account!.agencia,
						agenciaDigito: "",
						conta: account!.conta,
						contaDigito: account!.contaDigito,
						contaPgto: `00${account!.conta}${account!.contaDigito}`,
						tipoConta: account!.tipoConta,
					},
					transactionKey: account!.transactionKey,
				};
			}
		}
		try {
			// Retry the missing steps
			const response: AxiosResponse = await retryAccountCreationSteps(payload!);

			if (response.status === 200 || response.status === 201) {
				setLoadingStepsTry(false);
				setIsStepMissing(false);

				//re-fetch Transactions log
				const transactions = await searchLogTransactions(
					account!.transactionKey
				);
				if (transactions.data) {
					const trans = transactions.data.sort((a: any, b: any) => {
						return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
					});
					setAccountLogTransactions(trans);
				}
			} else {
				window.alert("Algo deu errado");
				setLoadingStepsTry(false);
			}
		} catch (error) {
			window.alert("Algo deu errado");
			setLoadingStepsTry(false);
		}
	};

	const ActionsPanel = () => {
		return (
			<div className="floating-panel">
				{user?.aprovado === ClientePfAprovado.WAITING_MANUAL_APPROVAL &&
					(denyBox ? (
						<div>
							<label>Qual é o motivo da recusa?</label>
							<textarea
								className="w-100 k4n-text-area"
								rows={3}
								placeholder="Digite aqui o motivo"
								id="observacao-input"
							></textarea>
							<div>
								<button
									className="action-button"
									onClick={() => setDenyBox(false)}
								>
									Cancelar
								</button>
								<button
									className="mx-3 action-button"
									onClick={() => denyProposal()}
								>
									Confirmar recusa
								</button>
							</div>
						</div>
					) : (
						<div>
							<button
								className="action-button"
								onClick={() => approveProposal()}
							>
								Validar cadastro
							</button>
							<button
								className="mx-3 action-button"
								onClick={() => setDenyBox(true)}
							>
								Recusar cadastro
							</button>
						</div>
					))}
				{user?.aprovado === ClientePfAprovado.WAITING_ACCOUNT_OPEN && (
					<div>
						<button className="action-button" onClick={() => openAccount()}>
							Processar abertura manualmente
						</button>
					</div>
				)}
			</div>
		);
	};

	if (loading) {
		return <div className="text-center mt-5">Carregando...</div>;
	}

	if (user) {
		return (
			<div className="container">
				<div className="my-4">
					<button className="action-button" onClick={() => navigate("/users")}>
						{" "}
						&#8592; &nbsp;{"Voltar"}
					</button>
				</div>
				<div className="card my-4 p-4">
					{userDocs.length > 0 && (
						<div>
							<div className="row">
								<div className="col-4">
									<h3 className="" style={{ backgroundColor: "transparent" }}>
										Dados do Usuário
									</h3>
									<hr className="my-3" />

									<div className="p-4">
										<div className="mb-3">
											<b>Nome</b>
											<div>{user.nome || "-"}</div>
										</div>
										<div className="mb-3">
											<b>CPF</b>
											<div>{formatCpf(user.cpf) || "-"}</div>
										</div>
										<div className="mb-3">
											<b>Data de Nascimento</b>
											<div>
												{dayjs(user.dtNasc).format("DD/MM/YYYY") || "-"}
											</div>
										</div>
										<div className="mb-3">
											<b>Celular</b>
											<div>
												{(user.phones &&
													user.phones[0] &&
													user.phones[0].phone) ||
													"-"}
											</div>
										</div>
										<div className="mb-3">
											<b>Apelido</b>
											<div>{user.apelido || "-"}</div>
										</div>
										<div className="mb-3">
											<b>Nome da mãe</b>
											<div>{user.nomeMae || "-"}</div>
										</div>
										<div className="mb-3">
											<b>Conta</b>
											<div>
												{user.accounts?.length
													? user.accounts[0].conta +
													  "-" +
													  user.accounts[0].contaDigito
													: "-"}
											</div>
										</div>
										<div className="mb-3">
											<b>Email</b>
											<div>
												{user.emails?.length ? user.emails[0].email : "-"}{" "}
											</div>
										</div>
									</div>
								</div>
								<div className="col-8">
									<h3 style={{ backgroundColor: "transparent" }}>Documentos</h3>
									<hr className="my-3" />
									{userDocs
										.filter((d: any) => d.doc.documentType)
										.map((doc: any, i: number) => (
											<div className="row">
												<div className="col-6 mt-4">
													<Zoom>
														<img
															src={doc.awsUrl}
															width={"100%"}
															alt="document"
															style={{ cursor: "zoom-in" }}
														/>
													</Zoom>
													<div
														onClick={() => toggleDisplayDoc(i)}
														style={{ cursor: "pointer", fontSize: "14px" }}
													>
														<img src={NavbarEye} alt="" width={14} />{" "}
														{doc.displayDetails ? "Esconder" : "Mostrar"}{" "}
														extração completa
													</div>
												</div>

												<div className="col-6 mt-4">
													<div className="mb-3">
														<b>Documento Federal</b>
														<div>
															{doc?.ocr?.extraction?.federalRevenueNumber ||
																"-"}
														</div>
													</div>
													<div className="mb-3">
														<b>Documento: </b>
														<div>{doc?.ocr?.classification?.type || "-"}</div>
													</div>
													<div className="mb-3">
														<b>Confiabilidade: </b>
														<div>
															{doc?.ocr?.classification?.sides[0]?.confidence ||
																"-"}
														</div>
													</div>
												</div>

												{doc.displayDetails && (
													<div className="col-12" key={i}>
														<JSONInput
															id="preview_json"
															locale={locale}
															placeholder={doc.ocr}
															height="500px"
															width="100%"
															viewOnly={true}
														/>
														<hr className="my-3" />
													</div>
												)}
											</div>
										))}
								</div>
							</div>
							<div className="row mt-5">
								<div className="col-4" style={{ minHeight: "500px" }}>
									<h2>Endereço</h2>
									<hr className="my-3" />

									{user.addresses &&
										(() => {
											const address = user.addresses[0];
											return (
												<div className="">
													<div className="mb-3">
														<b>CEP</b>
														<div>{address.cep}</div>
													</div>
													<div className="mb-3">
														<b>Bairro</b>
														<div>{address.bairro}</div>
													</div>
													<div className="mb-3">
														<b>Cidade</b>
														<div>{address.cidade}</div>
													</div>
													<div className="mb-3">
														<b>UF</b>
														<div>{address.uf}</div>
													</div>
													<div className="mb-3">
														<b>Número</b>
														<div>{address.numero}</div>
													</div>
													<div className="mb-3">
														<b>Logradouro</b>
														<div>{address.logradouro}</div>
													</div>
													<div className="mb-3">
														<b>Complemento</b>
														<div>{address.complemento}</div>
													</div>
												</div>
											);
										})()}
								</div>
								<div className="col-8 mb-4">
									<h2>Comprovante de residência</h2>
									<hr className="my-3" />
									{userDocs
										// .filter((d: any) => !d.doc.documentType)
										.map((doc: any, i: number) => (
											<div key={i} className="row">
												{doc?.ocr?.classification?.type ===
													"PROOF-OF-RESIDENCE" && (
													<>
														<div className="col-6">
															<Zoom>
																<img
																	src={doc.awsUrl}
																	width={"100%"}
																	alt="document"
																/>
															</Zoom>
															<div
																onClick={() => toggleDisplayDoc(i)}
																style={{ cursor: "pointer", fontSize: "14px" }}
															>
																<img src={NavbarEye} alt="" width={14} />{" "}
																{doc.displayDetails ? "Esconder" : "Mostrar"}{" "}
																extração completa
															</div>
														</div>
														<div className="col-6">
															<div className="">
																<div className="mb-3">
																	<b>CEP</b>
																	<div>{doc.ocr?.extraction?.zipCode}</div>
																</div>
																<div className="mb-3">
																	<b>Endereço</b>
																	<div>
																		{doc.ocr?.extraction?.number}{" "}
																		{doc.ocr?.extraction?.address}
																	</div>
																</div>
																<div className="mb-3">
																	<b>Cidade</b>
																	<div>{doc.ocr?.extraction?.city}</div>
																</div>
																<div className="mb-3">
																	<b>UF</b>
																	<div>{doc.ocr?.extraction?.state}</div>
																</div>
																<div className="mb-3">
																	<b>Complemento</b>
																	<div>
																		{doc.ocr?.extraction?.addressComplement}
																	</div>
																</div>
															</div>
														</div>
														{doc.displayDetails && (
															<div className="col-12" key={i}>
																<JSONInput
																	id="preview_json"
																	locale={locale}
																	placeholder={doc.ocr}
																	height="500px"
																	width="100%"
																	viewOnly={true}
																/>
																<hr className="my-3" />
															</div>
														)}
													</>
												)}
											</div>
										))}
								</div>
							</div>

							<div className="row mt-2">
								<div className="buttonRow">
									<h2>Log de Transações</h2>
									{isStepMissin ? (
										<>
											{loadingStepsTry ? (
												<button className="action-button">
													<div className="loader" />
												</button>
											) : (
												<button
													className="action-button"
													onClick={retryMissinSteps}
												>
													<FaRetweet size={24} />
												</button>
											)}
										</>
									) : null}
								</div>
								<hr className="my-3" />

								<div>
									<table className="table table-striped mb-4">
										<thead>
											<tr>
												<th className="table-column-title" scope="col">
													Operação
												</th>
												<th>Sucesso</th>
												<th>Data</th>
											</tr>
										</thead>
										<tbody>
											{accountLogTransactions &&
												accountLogTransactions.length > 0 && (
													<>
														{accountLogTransactions.map(
															(txData: any, i: number) => (
																<>
																	<tr
																		key={i}
																		className="interactive-table-row"
																		onClick={() => toggleDisplayLog(i)}
																	>
																		<td>
																			{!displayLogDetails[i] && (
																				<FaAngleDoubleDown></FaAngleDoubleDown>
																			)}
																			{displayLogDetails[i] && (
																				<FaAngleDoubleRight></FaAngleDoubleRight>
																			)}
																			<span className="pl-2">
																				{txData.req?.uri}
																			</span>
																		</td>
																		<td>
																			<button
																				type="button"
																				className={`btn btn-sm ${
																					txData.res?.sucesso
																						? "btn-success"
																						: "btn-danger"
																				}`}
																			>
																				{txData.res?.sucesso
																					? "SUCESSO"
																					: "ERRO"}
																			</button>
																		</td>
																		<td>
																			{dayjs(txData.date).format(
																				"DD/MM/YYYY HH:mm"
																			)}
																		</td>
																	</tr>
																	{displayLogDetails[i] && (
																		<tr>
																			<td style={{ columnSpan: "all" }}>
																				<JSONInput
																					id="preview_json"
																					locale={locale}
																					placeholder={txData}
																					height="500px"
																					width="100%"
																					viewOnly={true}
																				/>
																			</td>
																		</tr>
																	)}
																</>
															)
														)}
													</>
												)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					)}
				</div>
				<ActionsPanel />
			</div>
		);
	} else {
		return <div className="text-center mt-5">Usuário não encontrado</div>;
	}
};

export default UserPage;
